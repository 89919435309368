import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import VueApexCharts from "vue3-apexcharts";
import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueApexCharts);
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB5-0UsaNwRGozjP806L98gSD8OcKCtEWM',
    },
}).mount('#app')

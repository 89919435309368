<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <!-- <CDropdownItem>
        <CIcon icon="cil-bell" /> Updates
        <CBadge color="info" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-envelope-open" /> Messages
        <CBadge color="success" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-task" /> Tasks
        <CBadge color="danger" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-comment-square" /> Comments
        <CBadge color="warning" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem> -->
      <CDropdownItem style="cursor: pointer"> <CIcon icon="cil-user" /> Profile </CDropdownItem>

      <CDropdownItem style="cursor: pointer" @click="logout"> <CIcon icon="cil-lock-locked" /> Logout </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/10.png'
import axios from 'axios'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  methods: {
    async logout(){
      let formdata = new FormData();
      formdata.append("token", localStorage.getItem('token'));

      try {
        await axios.post(process.env.VUE_APP_ROOT_API +  "/api/user/logout", formdata, '').then(res => {
          if(res.data.status == "SUCCESS"){
            localStorage.clear()
            this.$router.push("/pages/login");
            location.reload();
          }else{
            alert(res.data.response.msg)
          }
            
          });
          
      } catch (error) {
        alert('Logout gagal, silahkan coba kembali')
      }
      
    }
  }
}
</script>
